// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
	production: false,
	//local
	/*APP_BASE_URL: "http://localhost:4200/",
	APP_SIGNIN_CALLBACK: "http://localhost:4200/authenticated",
	APP_SIGNOUT_CALLBACK: "http://localhost:4200/logout",*/

	APP_BASE_URL: "https://sales.eassist.me/",
	APP_SIGNIN_CALLBACK: "https://sales.eassist.me/authenticated",
	APP_SIGNOUT_CALLBACK: "https://sales.eassist.me/logout",
	
	APP_USER_POOL_ID: "us-east-1_weIudtO3y",
	APP_CLIENT_ID: "7ua2e3pf1jcmn9u3cpo6sq28ng",
	APP_STAGE: "dev",
	APP_API_HOST: "ynegt90fl8.execute-api.us-east-1.amazonaws.com"
	/*APP_USER_POOL_ID: "us-east-1_weIudtO3y",
	APP_CLIENT_ID: "7ua2e3pf1jcmn9u3cpo6sq28ng",
	APP_STAGE: "prod",
	APP_API_HOST: "w8jani0uqb.execute-api.us-east-1.amazonaws.com"*/
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
 // import 'zone.js/dist/zone-error';  // Included with Angular CLI.
